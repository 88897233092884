import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material'
import React from 'react'
import { initialProductFormdata, ProductSchema } from './constants';
import { IProductBEFormData, IProductFormProps } from './interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import isFieldError from '../../../../components/utilities/isFieldError';
import {useFieldArray, useForm } from 'react-hook-form';
import DialogForm from '../../../../components/dialog_form';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import './Form.scss'


import moment from 'moment';

import { ALLOWED_IMAGE_TYPE, FORMAT_DATE_INPUT, FORMAT_DATE_POST, MAX_IMAGE_FILESIZE, MAX_IMAGE_SIZETEXT } from '../../../../constants/Parameter';
import InputNumberFormat from '../../../../components/input_number_format';
import { IFormDataOption } from '../../../../components/interfaces/IFormData';
import { ImageAssignment } from '../../interfaces';
import { invalidMaxSizeFile, invalidMimetypeFile } from '../../../../constants/ErrorMessage';
import { HiPlus } from 'react-icons/hi';
import { IoTrash } from 'react-icons/io5';
import { IProductImage } from '../../../../components/Detail/interfaces';



const categoriesOption = [
{
  value : 'handphone',
  label : 'Handphone'
},
{
  value : 'laptop',
  label : 'Laptop'
}
]


const Form = ({
    isOpen, 
    onClose, 
    open = false,
    selectedData,
    onSave,
    userID,
    images,
     ...other
    }: IProductFormProps) => {

      const [startDate, setStartDate] = React.useState<null | Date>(null)
      const [endDate, setEndDate] = React.useState<null | Date>(null)
      const [postingDate, setPostingDate] = React.useState<null | Date>(null)
      const [selectedCategory, setSelectedCategory] = React.useState<IFormDataOption | null>(null)
      const [arrImageUrl, setArrImageUrl] = React.useState<string[]>([''])
      const [dataImages, setDataImages] = React.useState<IProductImage[]>([]);


      const {
        control,
        handleSubmit,
        reset,
        register,
        watch,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
      } = useForm<IProductBEFormData>({
        defaultValues: initialProductFormdata,
        resolver: yupResolver(ProductSchema),
      })
      

    const onEnter = () => {
        console.log(selectedData)
        setValue('action', selectedData?.id != undefined ? 'Edit' : 'Add')
        setValue('id', selectedData?.id ?? '')
        if (selectedData?.id) {
          setValue('title', selectedData.title)
          setValue('categoryID', selectedData.categoryID)
          setValue('category', selectedData.category)
          const selectedCategory = categoriesOption.find(
            (item) => item.value === selectedData.categoryID,
          )
          setSelectedCategory(selectedCategory || null)

          setValue('description', selectedData.description)
          setValue('startingPrice', selectedData.startingPrice)
          setValue('postingDate', selectedData.postingDate)
          onChangePostingDate(new Date(moment(selectedData.postingDate).toDate()))
          setValue('imgUrls', selectedData.imgUrls)
          onChangeStartDate(new Date(moment(selectedData.bidStartDate).toDate()))
          setValue('bidStartDate', moment(selectedData.bidStartDate).format('YYYY-MM-DD HH:mm:ss'))
          onChangeEndDate(new Date(moment(selectedData.bidEndDate).toDate()))
          setValue('bidEndDate', moment(selectedData.bidEndDate).format('YYYY-MM-DD HH:mm:ss'))

          setArrImageUrl([''])
          if (images){
            if (images.length > 0) {
              const imgID = images.map((x) => x.imagePath)
              setArrImageUrl(imgID)
            }
          }
        }
      }


      const onExit = () => {
        reset(initialProductFormdata)
        setStartDate(null)
        setEndDate(null)
        setPostingDate(null)
        setArrImageUrl([''])
      }


      const setValueDate = async (value: Date | string, type: string) => {
        if (value != '') {
          const newValue = moment(value).format(FORMAT_DATE_POST)
    
          setValue(type == 'start' ? 'bidStartDate' : 'bidEndDate', newValue)
        } else {
          setValue(type == 'start' ? 'bidStartDate' : 'bidEndDate', '')
        }
      }

      const setValuePostingDate = async (value: Date | string, type: string) => {
        if (value != '') {
          const newValue = moment(value).format(FORMAT_DATE_POST)
    
          setValue(type == 'posting' ? 'postingDate' : 'postingDate', newValue)
        } else {
          setValue(type == 'posting' ? 'postingDate' : 'postingDate', '')
        }
      }

      const onChangePostingDate = (value: Date | null) => {
        const date = moment(value)
        if (date.isValid()) {
          setPostingDate(value)
          setValuePostingDate(value ? value : '', 'posting')
        } else {
          setValueDate('', 'posting')
        }
      }
    
      const onChangeStartDate = (value: Date | null) => {
        const date = moment(value)
        if (date.isValid()) {
          setStartDate(value)
          setValueDate(value ? value : '', 'start')
        } else {
          setValueDate('', 'start')
        }
      }
      const onChangeEndDate = (value: Date | null) => {
        const date = moment(value)
        if (date.isValid()) {
          setEndDate(value)
          setValueDate(value ? value : '', 'end')
        } else {
          setValueDate('', 'end')
        }
      }

      const onSubmit = (values: IProductBEFormData) => {
        const updatedData: IProductBEFormData = {
          ...values,
          action: selectedData ? 'Edit': 'Add',
          sessionID: userID,
          imgUrls: arrImageUrl.join(','),
          imgIsMain: arrImageUrl[0],
          bidStartDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          bidEndDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss')

          // ID: selectedData ? selectedData.ID : '',
        };
        console.log(updatedData)
         onSave(updatedData);
      };



      const appendImage = () => {
        const imgUrl = ''
        setArrImageUrl([...arrImageUrl, imgUrl])
      };
    
    
      const handleTextImages = (index: number, value: string) => {
        const updatedTextValues = [...arrImageUrl]
        updatedTextValues[index] = value
        setArrImageUrl(updatedTextValues)
      }

      const removeImage = (index: number) => {
        setArrImageUrl(arrImageUrl.filter((_, x) => x !== index));
      };



  return (
    <DialogForm
    open={isOpen}
    title={selectedData ? 'Edit Item' : 'Add Item'}
    size="sm"
    onClose={onClose}
    onCancel={onClose}
    onConfirm={handleSubmit(onSubmit)}
    TransitionProps={{
      onEnter: onEnter,
      onExit: onExit,
    }}
    labelConfirmButton="SAVE"
    labelCancelButton="CANCEL"
    {...other}
  >
    <form>
      <div className="">
      <div className="">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">ID * </FormLabel>
            <TextField
              size="small"
              placeholder="Type here"
              disabled={selectedData ? true : false}
              style={selectedData ? {backgroundColor: '#D2D2D2'} : {}}
              error={isFieldError(errors, 'id')}
              helperText={errors.id?.message}
              {...register('id')}
            />
          </FormControl>
        </div>
        <div className="">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Title *</FormLabel>
            <TextField
              size="small"
              placeholder="Type here"
              error={isFieldError(errors, 'title')}
              helperText={errors.title?.message}
              {...register('title')}
            />
          </FormControl>
        </div>
        <div className="">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Category *</FormLabel>
            <TextField
              size="small"
              placeholder="Type here"
              error={isFieldError(errors, 'category')}
              helperText={errors.category?.message}
              {...register('category')}
            />
          </FormControl>
        </div>
        {/* <FormControl fullWidth margin="dense">
            <FormLabel className="campus-location__formlabel">Category *</FormLabel>
            <Controller
              control={control}
              name="CategoryID"
              render={({ field: { onChange } }) => (
                <Autocomplete
                  disableClearable={selectedCategory !== null}
                  value={selectedCategory}
                  options={categoriesOption}
                  onChange={(_, newValue) => {
                    onChange(newValue?.value)
                    setSelectedCategory(newValue)
                    setValue('Category', newValue?.label)
                  }}
                  getOptionLabel={(option) => option.label || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Please select"
                      error={isFieldError(errors, 'CategoryID')}
                      helperText={errors.CategoryID?.message}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: loading ? (
                          <InputAdornment position="start">
                            <Skeleton width={100} />
                          </InputAdornment>
                        ) : (
                          params.InputProps.startAdornment
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </FormControl> */}
        <div className="">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Description *</FormLabel>
            <TextField
              size="small"
              multiline
              minRows={3}
              placeholder="Type here"
              error={isFieldError(errors, 'description')}
              helperText={errors.description?.message}
              {...register('description')}
            />
          </FormControl>
        </div>
        <div className="complex-form__formwrapper">
          <FormControl margin="dense">
            <FormLabel className="complex-form__formlabel">Starting Price *</FormLabel>
            <TextField
              size="small"
              placeholder="Type here"
              error={isFieldError(errors, 'startingPrice')}
              helperText={errors.startingPrice?.message}
              InputProps={{
                inputComponent: InputNumberFormat as any,
                inputProps: {
                  value: watch('startingPrice'),
                },
              }}
              {...register('startingPrice')}
            />
          </FormControl>
        </div>
        <div className="complex-form__formwrapper">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Posting Date *</FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                showDaysOutsideCurrentMonth
                className="hidden sm:block"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={postingDate}
                onChange={onChangePostingDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'postingDate')}
                    helperText={errors.postingDate?.message}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="complex-form__formwrapper">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Bid Start Date *</FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                showDaysOutsideCurrentMonth
                className="hidden sm:block"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={startDate}
                onChange={onChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'bidStartDate')}
                    helperText={errors.bidStartDate?.message}
                  />
                )}
              />
          <TimePicker
            value={startDate}
            onChange={onChangeStartDate}
            ampm={false}
            renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
          />
              {/* <MobileDatePicker
                showDaysOutsideCurrentMonth
                className="block sm:hidden"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={startDate}
                onChange={onChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'BidStartDate')}
                    helperText={errors.BidStartDate?.message}
                  />
                )}
              /> */}
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="complex-form__formwrapper">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Bid End Date *</FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                showDaysOutsideCurrentMonth
                className="hidden sm:block"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={endDate}
                onChange={onChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'bidEndDate')}
                    helperText={errors.bidEndDate?.message}
                  />
                )}
              />
          <TimePicker
            value={endDate}
            onChange={onChangeEndDate}
            ampm={false}
            renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
          />
              {/* <MobileDatePicker
                showDaysOutsideCurrentMonth
                className="block sm:hidden"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={endDate}
                onChange={onChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'BidEndDate')}
                    helperText={errors.BidEndDate?.message}
                  />
                )}
              /> */}
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="flex flex-col w-full mt-3">
          {arrImageUrl.map((field, index) => (
                    <div key={index} className='flex w-full flex-col mb-2'>
                      <FormControl fullWidth margin="dense">
                      <FormLabel className="collection-management__formlabel">Image {index + 1} </FormLabel>
                    <div className='flex w-full flex-row gap-2'>
                        <TextField
                          variant="outlined"
                          size="small"
                          placeholder={`Put Image Url here`}
                          fullWidth
                          value={field}
                          onChange={(e) => handleTextImages(index, e.target.value)}
                        />
                      <div className='flex flex-row gap-1'>
                          <IconButton
                            color="secondary"
                            size="medium"
                            onClick={appendImage}
                          >
                            <HiPlus />
                          </IconButton>

                          {index > 0 && (
                            <IconButton 
                            color="error"
                            size="medium"
                            onClick={()=>removeImage(index)} 
                            >
                              <IoTrash />
                            </IconButton>
                          )}
                      </div>
                    </div>
                      </FormControl>                     
                    </div>
                ))}
        </div>
      </div>
    </form>
  </DialogForm>
  )
}

export default Form


