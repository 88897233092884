import { Typography } from '@mui/material'
import { initialProductFormdata, ProductSchema } from './constants';
import { IDeleteFormProps, IProductBEFormData } from './interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import DialogForm from '../../../../components/dialog_form';


const Form = ({
    isOpen, 
    onClose, 
    open = false,
    userID,
    onSave,
    selectedID,
    selectedData,
     ...other
    }: IDeleteFormProps) => {

      const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm<IProductBEFormData>({
        defaultValues: initialProductFormdata,
        //resolver: yupResolver(ProductSchema),
      })
      

    const onEnter = () => {
      }


      const onExit = () => {
        reset(initialProductFormdata)
      }

      const onSubmit = (values: IProductBEFormData) => {
        const updatedData: IProductBEFormData = {
          action: 'Delete',
          id: selectedID ?? '',
          sessionID: userID,
          // ID: selectedData ? selectedData.ID : '',
        };
        console.log(updatedData)
         onSave(updatedData);
        // navigation.navigate('Products' as never) 
      };



  return (
    <DialogForm
    open={isOpen}
    title={'Confirmation'}
    size="xs"
    onClose={onClose}
    onCancel={onClose}
    onConfirm={handleSubmit(onSubmit)}
    TransitionProps={{
      onEnter: onEnter,
      onExit: onExit,
    }}
    labelConfirmButton="YES"
    labelCancelButton="NO"
    {...other}
  >
    <form>
        <div>
            <Typography>Are you sure want to delete <b>{selectedData?.title}</b> ?</Typography>
        </div>
    </form>
  </DialogForm>
  )
}

export default Form


