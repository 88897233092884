import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import React, { useEffect } from 'react'
import Datagrid from './components/datagrid';
import { IUserBE } from './interfaces';
const apiUrl = process.env.REACT_APP_APIURL;

const UserManagement = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const [data, setData] = React.useState<IUserBE[]>([]);


  const fetchData = async (userID: string) => {
      try {
        const response = await axios.get(`${apiUrl}/admin/getUser?sessionID=${userID}`);
        if (response.data.status) setData(response.data.data);
        else window.location.href = '/';
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  useEffect(() => {
    if (isSignedIn) {
      fetchData(user?.id ?? '');
    } else window.location.href = '/';

    }, []);
  return (
    <div className='mx-3'>
      <div className='mb-5'>
      <h1 className='font-bold text-3xl'>USER MANAGEMENT</h1>
      </div>
      <Datagrid dataArray={data}/>
    </div>
  )
}

export default UserManagement
