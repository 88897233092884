import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, InputAdornment, Skeleton, TextField } from '@mui/material'
import React from 'react'
import { initialCategoryFormdata, CategorySchema } from './constants';
import { ICategoryBEFormData, ICategoryFormProps } from './interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import isFieldError from '../../../../components/utilities/isFieldError';
import { Controller, useForm } from 'react-hook-form';
import DialogForm from '../../../../components/dialog_form';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import './Form.scss'

import moment from 'moment';

import { FORMAT_DATE_INPUT, FORMAT_DATE_POST } from '../../../../constants/Parameter';
import InputNumberFormat from '../../../../components/input_number_format';
import { IFormDataOption } from '../../../../components/interfaces/IFormData';



const categoriesOption = [
{
  value : 'handphone',
  label : 'Handphone'
},
{
  value : 'laptop',
  label : 'Laptop'
}
]


const Form = ({
    isOpen, 
    onClose, 
    open = false,
    selectedData,
    onSave,
    userID,
     ...other
    }: ICategoryFormProps) => {

      const [startDate, setStartDate] = React.useState<null | Date>(null)
      const [endDate, setEndDate] = React.useState<null | Date>(null)
      const [postingDate, setPostingDate] = React.useState<null | Date>(null)

      const {
        control,
        handleSubmit,
        reset,
        register,
        watch,
        setValue,
        formState: { errors },
      } = useForm<ICategoryBEFormData>({
        defaultValues: initialCategoryFormdata,
        resolver: yupResolver(CategorySchema),
      })
      

    const onEnter = () => {
        console.log(selectedData)
        setValue('action', selectedData?.id != undefined ? 'Edit' : 'Add')
        setValue('id', selectedData?.id ?? '')
        if (selectedData?.id) {
          setValue('category', selectedData.category)
          onChangeStartDate(new Date(moment(selectedData.bidStartDate).toDate()))
          setValue('bidStartDate', moment(selectedData.bidStartDate).format('YYYY-MM-DD HH:mm:ss'))
          onChangeEndDate(new Date(moment(selectedData.bidEndDate).toDate()))
          setValue('bidEndDate',  moment(selectedData.bidEndDate).format('YYYY-MM-DD HH:mm:ss'))
        }
      }


      const onExit = () => {
        reset(initialCategoryFormdata)
        setStartDate(null)
        setEndDate(null)
        setPostingDate(null)
      }


      const setValueDate = async (value: Date | string, type: string) => {
        if (value != '') {
          const newValue = moment(value).format(FORMAT_DATE_POST)
    
          setValue(type == 'start' ? 'bidStartDate' : 'bidEndDate', newValue)
        } else {
          setValue(type == 'start' ? 'bidStartDate' : 'bidEndDate', '')
        }
      }

      const onChangeStartDate = (value: Date | null) => {
        const date = moment(value)
        if (date.isValid()) {
          setStartDate(value)
          setValueDate(value ? value : '', 'start')
        } else {
          setValueDate('', 'start')
        }
      }
      const onChangeEndDate = (value: Date | null) => {
        const date = moment(value)
        if (date.isValid()) {
          setEndDate(value)
          setValueDate(value ? value : '', 'end')
        } else {
          setValueDate('', 'end')
        }
      }

      const onSubmit = (values: ICategoryBEFormData) => {
        const updatedData: ICategoryBEFormData = {
          ...values,
          action: 'Edit',
          sessionID: userID,
          bidStartDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          bidEndDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
          // ID: selectedData ? selectedData.ID : '',
        };
        console.log(updatedData)
         onSave(updatedData);
        // navigation.navigate('Categorys' as never) 
      };



  return (
    <DialogForm
    open={isOpen}
    title={'Edit Category'}
    size="sm"
    onClose={onClose}
    onCancel={onClose}
    onConfirm={handleSubmit(onSubmit)}
    TransitionProps={{
      onEnter: onEnter,
      onExit: onExit,
    }}
    labelConfirmButton="SAVE"
    labelCancelButton="CANCEL"
    {...other}
  >
    <form>
      <div className="">
        <div className="">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Category Name *</FormLabel>
            <TextField
              size="small"
              placeholder="Type here"
              error={isFieldError(errors, 'name')}
              helperText={errors.category?.message}
              {...register('category')}
            />
          </FormControl>
        </div>

        <div className="complex-form__formwrapper">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Bid Start Date *</FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                showDaysOutsideCurrentMonth
                className="hidden sm:block"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={startDate}
                onChange={onChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'bidStartDate')}
                    helperText={errors.bidStartDate?.message}
                  />
                )}
              />
            <TimePicker
                value={startDate}
                onChange={onChangeStartDate}
                ampm={false}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
              />
              {/* <MobileDatePicker
                showDaysOutsideCurrentMonth
                className="block sm:hidden"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={startDate}
                onChange={onChangeStartDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'BidStartDate')}
                    helperText={errors.BidStartDate?.message}
                  />
                )}
              /> */}
            </LocalizationProvider>
          </FormControl>
        </div>
        <div className="complex-form__formwrapper">
          <FormControl fullWidth margin="dense">
            <FormLabel className="complex-form__formlabel">Bid End Date *</FormLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                showDaysOutsideCurrentMonth
                className="hidden sm:block"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={endDate}
                onChange={onChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'bidEndDate')}
                    helperText={errors.bidEndDate?.message}
                  />
                )}
              />
              <TimePicker
                value={endDate}
                onChange={onChangeEndDate}
                ampm={false}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
              />
              {/* <MobileDatePicker
                showDaysOutsideCurrentMonth
                className="block sm:hidden"
                mask="__/__/____"
                // minDate={new Date()}
                inputFormat={FORMAT_DATE_INPUT}
                value={endDate}
                onChange={onChangeEndDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={isFieldError(errors, 'BidEndDate')}
                    helperText={errors.BidEndDate?.message}
                  />
                )}
              /> */}
            </LocalizationProvider>
          </FormControl>
        </div>
      </div>
    </form>
  </DialogForm>
  )
}

export default Form


