import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { HiOutlineViewList } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import { SignInButton, SignedIn, SignedOut, UserButton, useUser } from '@clerk/clerk-react';
import { ICategory } from './interfaces';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_APIURL;

const Header = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const isSearchPage = useMatch('/search');
  const drawerRef = useRef<HTMLDivElement>(null); // Define drawerRef with useRef

  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenProfile, setIsOpenProfile] = React.useState(false);
  const [keyword, setKeyword] = React.useState<string>('');
  const [dataCategories, setDataCategories] = React.useState<ICategory[]>([]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(apiUrl + '/public/getCategory');
      setDataCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
    setIsOpenProfile(false); // Close profile if open
  };

  const toggleProfile = () => {
    setIsOpenProfile((prev) => !prev);
    setIsOpen(false);
  };

  const onCloseProfile = () => {
    setIsOpenProfile(false);
  };

  const handleSearch = () => {
    const keywordValue = encodeURIComponent(keyword || '');

    navigate(`/search?keyword=${keywordValue}`, { state: { from: location }, replace: true });

    if (isSearchPage) navigate(0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    fetchCategories();

    const handleOutsideClick = (event: MouseEvent) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <header className="text-black py-4">
      <div className="flex flex-row ">
        <div className="mx-2 items-center hidden md:block " style={{ width: '20%', height: '20%', alignItems: 'center', alignSelf: 'center' }}>
          <Link to="/" className="font-bold" style={{ width: '70%' }}>
            <img src={require('../../assets/img/white-mola.png')} style={{ width: '100%', height: '30%',}} alt="Logo" />
          </Link>
        </div>
        <div className="w-3/5 my-auto mx-auto">
          <input
            type="text"
            placeholder="Search here..."
            value={keyword}
            className="p-3 border border-gray-300 rounded-xl w-full"
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className='xs:hidden md:flex flex-col text-white' style={{alignItems:'center', alignSelf:'center', width:'10%',}}>
        <SignedIn>
                <UserButton afterSignOutUrl="/doLogout" /> {isSignedIn ? user.fullName : ''}
              </SignedIn>
              <SignedOut>
                <SignInButton afterSignUpUrl="/doRegister" afterSignInUrl="/doRegister" />
              </SignedOut>
        </div>
      </div>
      <div className="w-full mx-auto xs:flex md:hidden items-center">
        <nav className="mx-4 w-full">
          <ul className="flex space-x-4 gap-5 justify-between">
            <div className="flex flex-row gap-5 ">
              <li className="mr-6 ">
                <button onClick={toggleDrawer} className="text-black font-bold text-2xl my-auto border-2 shadow-lg p-1 rounded-lg border-black hover:bg-orange-300">
                  <HiOutlineViewList size={30} />
                </button>
              </li>
              <div className="hidden md:block">
                <li className={'my-auto'}>
                  <Link to="/" className="font-bold xs:text-xl md:text-2xl">Home</Link>
                </li>
              </div>
            </div>

            <li className="justify-end">
              <SignedIn>
                <UserButton afterSignOutUrl="/" /> {isSignedIn ? user.fullName : ''}
              </SignedIn>
              <SignedOut>
                <SignInButton afterSignUpUrl="/doRegister" afterSignInUrl="/doRegister" />
              </SignedOut>
            </li>
          </ul>
        </nav>
      </div>
      <div className={`fixed top-0 left-0 h-screen w-60 bg-bluePrimary  z-10 ${isOpen ? 'translate-x-0' : '-translate-x-60'} transition-transform duration-300 ease-in-out`} ref={drawerRef}>
        <button onClick={toggleDrawer} className="absolute top-4 text-white right-4 p-2">
          <IoClose size={30} />
        </button>
        <div className="drawer">
          <ul className="mt-16">
            <li className="py-4 px-6 hover:bg-gray-200 text-xl font-bold text-white hover:text-black">
              <Link to="/">Home</Link>
            </li>
            {dataCategories.map((x, index) => (
              <li key={index} className="py-4 px-6 hover:bg-gray-200 text-xl font-bold text-white hover:text-black">
                <Link to={x.categoryID.toLowerCase()}>{x.category}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

    </header>
  );
};

export default Header;
