// Sidebar.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SidebarBE: React.FC = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownOpenStock, setDropdownOpenStock] = useState(false);
  const [isDropdownOpenReport, setDropdownOpenReport] = useState(false);


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdownReport = () => {
    setDropdownOpenReport(!isDropdownOpenReport);
  };
  const toggleDropdownStock = () => {
    setDropdownOpenStock(!isDropdownOpenStock);
  };

  return (
    <div className="sm:block hidden bg-gray-800 text-white h-screen  w-64 relative top-0 left-0 overflow-y-auto transition-transform transform duration-300 ease-in-out" style={{borderTopRightRadius:50, borderBottomRightRadius:50}} >
      <div className="p-2">
        {/* <h1 className="text-2xl font-bold">Admin Panel</h1>
        <button className="text-white" onClick={toggleDropdown}>
          &#x2715;
        </button> */}
      </div>
      <nav className="">
        <SidebarItem to="/admin/dashboard" label="Dashboard" />
        <div className="relative">    
          <div
            onClick={toggleDropdown}
            className="w-full text-left p-2 pr-8 focus:outline-none hover:bg-gray-700 cursor-pointer"
          >
            <div className='flex flex-row justify-between font-bold'>
                <h1>Manage Users</h1>
                <h1>&gt;</h1>
            </div>
          </div>
          {isDropdownOpen && (
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/user-management" label="User" />
              {/* <SidebarItem to="/admin/users/create" label="Create User" /> */}
            </div>
          )}
        </div>
        <div className="relative"> 
          <div
            onClick={toggleDropdownStock}
            className="w-full text-left p-2 pr-8 focus:outline-none hover:bg-gray-700 cursor-pointer"
          >
            <div className='flex flex-row justify-between font-bold'>
                <h1>Manage Item</h1>
                <h1>&gt;</h1>
            </div>
          </div>
          {isDropdownOpenStock && (
            <div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/item-management" label="Product" />
            </div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/category-management" label="Category" />
            </div>
            </div>
          )}
        </div>
        <div className="relative"> 
          <div
            onClick={toggleDropdownReport}
            className="w-full text-left p-2 pr-8 focus:outline-none hover:bg-gray-700 cursor-pointer"
          >
            <div className='flex flex-row justify-between font-bold'>
                <h1>Report</h1>
                <h1>&gt;</h1>
            </div>
          </div>
          {isDropdownOpenReport && (
            <div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/transaction-report" label="Transaction Report" />
            </div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/highest-bidder" label="Top 5 Highest Bidder" />
            </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

interface SidebarItemProps {
  to: string;
  label: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ to, label }) => {
  return (
   
      <Link to={to} className="block p-2 pl-3 hover:bg-gray-700">
        {label}
      </Link>

  );
};

export default SidebarBE;
