import axios from 'axios';
import React from 'react'
import { Link } from 'react-router-dom';
import { ICategory } from '../Header/interfaces';
const apiUrl = process.env.REACT_APP_APIURL;


const Sidebar = () => {
  const [dataCategories, setDataCategories] = React.useState<ICategory[]>([]);


  const fetchCategories = async () => {
    try {
      const response = await axios.get(apiUrl + '/public/getCategory');
      setDataCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={`relative top-0 left-0 h-screen w-60  z-10 translate-x-0 transition-transform duration-300 ease-in-out`} >
    <div className="drawer">
      <ul className="mt-16">
        <li className="py-4 px-6 hover:bg-gray-200 text-xl font-bold text-white hover:text-black">
          <Link to="/">Home</Link>
        </li>
        {dataCategories.map((x, index) => (
          <li key={index} className="py-4 px-6 hover:bg-gray-200 text-xl font-bold text-white hover:text-black">
            <Link to={x.categoryID.toLowerCase()}>{x.category}</Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
  )
}

export default Sidebar
