import { useUser } from '@clerk/clerk-react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react'
import { HiPlus } from 'react-icons/hi';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { IProductImage } from '../../components/Detail/interfaces';
import Datagrid from './components/datagrid'
import Form from './components/form/Form';
import FormDelete from './components/form/FormDelete';
import { IProductBEFormData, IUploadFormData } from './components/form/interfaces';
import { IProductBE } from './interfaces';

const apiUrl = process.env.REACT_APP_APIURL;

const AuctionManagement = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const [data, setData] = React.useState<IProductBE[]>([]);
  const [dataDetail, setDataDetail] = React.useState<IProductBE | null>(null);

  const [isOpenDetail, setIsOpenDetail] = React.useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false);
  const [selectedID, setSelectedID] = React.useState<string>('');
  const [selectedData, setSelectedData] = React.useState<IProductBE | null>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);
  const [alertErrMessage, setAlertErrMessage] = React.useState<string>('');
  const [fileName, setFileName] = React.useState<string>('');
  const [fileData, setFileData] = React.useState<string>('');
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [dataImages, setDataImages] = React.useState<IProductImage[]>([]);



  const onOpenDetail = (id: string) => {
    if (id !== '') {
      if (isSignedIn) {
    fetchDetail(id)
    fetchImages(id)
      }
    } else {
      setIsOpenDetail(true)
    }
  }

  const onCloseDetail = () => {
    setDataDetail(null)
    setIsOpenDetail(false)
  }

  const onOpenDelete = (data: IProductBE) => {
    setIsOpenDelete(true)
    setSelectedID(data.id)
    setSelectedData(data)
  }

  const onCloseDelete = () => {
    setIsOpenDelete(false)
  }
  const fetchData = async (userID: string) => {
      try {
        const response = await axios.get(`${apiUrl}/admin/getProduct?sessionID=${userID}`);
        if (response.data.status) {
          setData(response.data.data);
        }
        else window.location.href = '/';
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDetail = async (id: string) => {
      try {
        const response = await axios.get(`${apiUrl}/admin/getProduct?sessionID=${user?.id ?? ''}&id=${id}`);
        if (response.data.status) {
          setDataDetail(response.data.data);
          setIsOpenDetail(true)
        }
        else window.location.href = '/';
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchImages = async (id: string) => {
      try {
        const response = await axios.get(`${apiUrl}/public/getProductImage?id=${id}`);
        const data: IProductImage[] = response.data.data
        if (data){
        setDataImages(data)
        }            
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const onSave = async (data: IProductBEFormData) => {
      try {
        setAlertErrMessage('')
        const response = await axios.post(`${apiUrl}/admin/doSaveProduct`, data);
        if (response.status === 200) {
          if (response.data.status) {
            setShowAlert(true)
            onCloseDetail()
            onCloseDelete()
            setShowConfirmation(false)
          } else {
            setShowAlertError(true)
            setAlertErrMessage(response.data.message)
          }
          fetchData(user?.id ?? '')
        } else {
          setShowAlertError(true)
        }
      } catch (error) {
        console.error('Error saving data:', error);
      }
  };

  const MAX_CSV_FILESIZE = 1024 * 1024 * 10;

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files && files.length > 0) {
        const file = files[0]
        if (!file.type || !file.type.includes('csv')) {
            setFileData('')
            return
        }
        setFileName(file.name)

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setFileData(reader.result as string)
        }
    }
}

const onSubmit = () => {
  const updatedData: IProductBEFormData = {
    id: '',
    action: 'Upload',
    fileData: fileData,
    fileName: fileName,
    sessionID: user?.id ?? ''
    };
   onSave(updatedData);
};


  useEffect(() => {
    if (isSignedIn) {
      fetchData(user?.id ?? '');
    } else window.location.href = '/';

    }, []);
  return (
    <div className='mx-3  relative'>
      <div className='mb-5'>
      <h1 className='font-bold text-3xl'>PRODUCT MANAGEMENT</h1>
      </div>
      <div className="flex flex-row gap-3">
        <Button variant="contained" color="primary" onClick={()=>onOpenDetail('')}>
          Add
        </Button>
        <Button variant='outlined' className='border bg-gray-200 p-3' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius:5, }}>
      <label htmlFor="file-upload" className="file-upload-label flex flex-col" style={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Upload CSV File</Typography>
      </label>
      <input id="file-upload" type="file" accept=".csv" onChange={handleUpload} style={{ display: 'none' }} />
    </Button>
    {fileName !== '' && (
      <Typography style={{alignSelf:'center'}}>{fileName}</Typography>
    )}     
      </div>
      {fileName !== '' && (
        <div className='flex mt-4' >
        <Button variant="contained" color="primary" onClick={()=>setShowConfirmation(true)}>
          Submit
        </Button>
        </div>
      )}
      <Datagrid dataArray={data} onOpenDetail={onOpenDetail} onCloseDetail={onCloseDetail} onOpenDelete={onOpenDelete} />
      <Form open={isOpenDetail} isOpen={isOpenDetail} onClose={onCloseDetail} selectedData={dataDetail} onSave={onSave} userID={user?.id ?? ''} images={dataImages} />
      <FormDelete open={isOpenDelete} isOpen={isOpenDelete} onClose={onCloseDelete} onSave={onSave} userID={user?.id ?? ''} selectedID={selectedID} selectedData={selectedData} />

      <div>
      <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
        <DialogTitle className='flex flex-row gap-2' style={{alignItems:'center'}}>
          <Typography>Success</Typography>
        </DialogTitle>
        <DialogContent>
          Saved data successful.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} color="primary" autoFocus variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    <div>
      <Dialog open={showAlertError} onClose={() => setShowAlertError(false)}>
        <DialogTitle className='flex flex-row gap-2' style={{alignItems:'center'}}>
          <Typography>Failed</Typography>
        </DialogTitle>
        <DialogContent>
          {alertErrMessage == '' ? 'Save data failed.' : (<div dangerouslySetInnerHTML={{ __html: alertErrMessage }} />) } 
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlertError(false)} color="primary" autoFocus variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    <div>
      <Dialog open={showConfirmation} onClose={() => setShowConfirmation(false)}>
        <DialogTitle className='flex flex-row gap-2' style={{alignItems:'center'}}>
          <Typography>Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          Are you sure want to upload {fileName} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} color="primary" autoFocus variant='contained'>
            YES
          </Button>
          <Button onClick={() => setShowConfirmation(false)} color="error" autoFocus variant='contained'>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    </div>
  )
}

export default AuctionManagement
