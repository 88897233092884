import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const location = useLocation();
    const pathnameParts = location.pathname.split('/');

  return (
    <div style={{ display: 'flex' }}>
        {pathnameParts[1] !== 'admin' && (
        <div className='bg-bluePrimary xs:hidden md:flex' style={{borderTopRightRadius:50, WebkitBorderBottomRightRadius:30}}>
            <Sidebar /> 
      </div>
      )}
      <div style={{ flex: 1}}>
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
