import * as yup from 'yup'
import { ICategoryBEFormData } from '../interfaces'


export const CategorySchema = yup.object({
  category: yup.string().required().label('Category Name'),
  bidStartDate: yup.string().required().label('Bid Start Date'),
  bidEndDate: yup.string().required().label('Bid End Date'),
})

export const initialCategoryFormdata: ICategoryBEFormData = {
  action: 'Edit',
  id: '',
  category: '',
  bidStartDate: '',
  bidEndDate: '',
  sessionID: ''
}