import { useUser } from '@clerk/clerk-react';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react'
import Datagrid from './components/datagrid'
import Form from './components/form/Form';
import FormDelete from './components/form/FormDelete';
import { ICategoryBEFormData } from './components/form/interfaces';
import { ICategoryBE } from './interfaces';

const apiUrl = process.env.REACT_APP_APIURL;

const CategoryManagement = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  const [data, setData] = React.useState<ICategoryBE[]>([]);
  const [dataDetail, setDataDetail] = React.useState<ICategoryBE | null>(null);

  const [isOpenDetail, setIsOpenDetail] = React.useState<boolean>(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState<boolean>(false);
  const [selectedID, setSelectedID] = React.useState<string>('');
  const [selectedData, setSelectedData] = React.useState<ICategoryBE | null>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);



  const onOpenDetail = (id: string) => {
    console.log('clicked'+id)
    if (id !== '') {
      if (isSignedIn) {
    fetchDetail(id)
      }
    } else {
      setIsOpenDetail(true)
    }
    
  }

  const onCloseDetail = () => {
    setDataDetail(null)
    setIsOpenDetail(false)
  }

  const onOpenDelete = (data: ICategoryBE) => {
    setIsOpenDelete(true)
    setSelectedID(data.id)
    setSelectedData(data)
  }

  const onCloseDelete = () => {
    setIsOpenDelete(false)
  }
  const fetchData = async (userID: string) => {
      try {
        const response = await axios.get(`${apiUrl}/admin/getCategory?sessionID=${userID}`);
        if (response.data.status) {
          setData(response.data.data);
        }
        else window.location.href = '/';
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchDetail = async (id: string) => {
      try {
        const response = await axios.get(`${apiUrl}/admin/getCategory?sessionID=${user?.id ?? ''}&id=${id}`);
        if (response.data.status) {
          setDataDetail(response.data.data);
          setIsOpenDetail(true)
        }
        else window.location.href = '/';
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const onSave = async (data: ICategoryBEFormData) => {
      try {
        const response = await axios.post(`${apiUrl}/admin/doSaveCategory`, data);
        if (response.status === 200) {
          setShowAlert(true)
          onCloseDetail()
          onCloseDelete()
          fetchData(user?.id ?? '')
        } else {
          setShowAlertError(true)
          console.log('Error', 'Saving data failed');
        }
      
      } catch (error) {
        console.error('Error during saving:', error);
        console.log('Error', 'Something went wrong during saving data. Please try again.');
      }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchData(user?.id ?? '');
    } else window.location.href = '/';

    }, []);
  return (
    <div className='mx-3  relative'>
      <div className='mb-5'>
      <h1 className='font-bold text-3xl'>CATEGORY MANAGEMENT</h1>
      </div>
      <Datagrid dataArray={data} onOpenDetail={onOpenDetail} onCloseDetail={onCloseDetail} onOpenDelete={onOpenDelete} />
      <Form open={isOpenDetail} isOpen={isOpenDetail} onClose={onCloseDetail} selectedData={dataDetail} onSave={onSave} userID={user?.id ?? ''} />
      <FormDelete open={isOpenDelete} isOpen={isOpenDelete} onClose={onCloseDelete} onSave={onSave} userID={user?.id ?? ''} selectedID={selectedID} selectedData={selectedData} />

      <div>
      <Dialog open={showAlert} onClose={() => setShowAlert(false)}>
        <DialogTitle className='flex flex-row gap-2' style={{alignItems:'center'}}>
          <Typography>Success</Typography>
        </DialogTitle>
        <DialogContent>
          Saved data successful.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} color="primary" autoFocus variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    <div>
      <Dialog open={showAlertError} onClose={() => setShowAlertError(false)}>
        <DialogTitle className='flex flex-row gap-2' style={{alignItems:'center'}}>
          <Typography>Failed</Typography>
        </DialogTitle>
        <DialogContent>
          Save data failed.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)} color="primary" autoFocus variant='contained'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    </div>
  )
}

export default CategoryManagement
