
import React, { useEffect } from 'react'
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, useGridApiRef } from '@mui/x-data-grid'


import "react-datepicker/dist/react-datepicker.css";
import { DatagridProps } from './interfaces';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../constants/Parameter';
import moment from 'moment';

  
const Datagrid = ({dataArray}:DatagridProps) => {

  const [pageSize, setPageSize] = React.useState<number>(PAGE_SIZE)


  const apiRef = useGridApiRef();

  const columns: GridColDef[] = [
    { field: 'productID', headerName: 'Product ID', flex: 1, minWidth: 100 },
    { field: 'category', headerName: 'Category', flex: 1, minWidth: 100 },
    { field: 'product', headerName: 'Product', flex: 2, minWidth: 120 },
    { field: 'name', headerName: 'Name', flex: 2, minWidth: 120 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 120 },
    { field: 'bidPrice', headerName: 'Bid Price', flex: 1, minWidth: 120, 
      renderCell: (params) => {
      return `Rp.${parseInt(params.row.bidPrice).toLocaleString()}`
    },},
    { field: 'bidDate', headerName: 'Bid Date', flex: 1, minWidth: 155,
    renderCell: (params) => {
      return moment(params.row.bidDate).format('DD-MMM-YYYY hh:mm:ss')
    },
   },
    { field: 'bidStartDate', headerName: 'Bid Start Date', flex: 1, minWidth: 155,
    renderCell: (params) => {
      return moment(params.row.bidStartDate).format('DD-MMM-YYYY hh:mm:ss')
    },
   },
    { field: 'bidEndDate', headerName: 'Bid End Date', flex: 1, minWidth: 155,
    renderCell: (params) => {
      return moment(params.row.bidEndDate).format('DD-MMM-YYYY hh:mm:ss')
    },
   },
  ];

  function CustomToolbar() {
    const defaultCsvFileName = 'TRANSACTION REPORT'; // Default CSV file name
  
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ fileName: defaultCsvFileName }} // Set default file name when exporting CSV
        />
      </GridToolbarContainer>
    );
  }
  

  useEffect(() => {
  }, []);
  
  return (
<div className="w-full p-4">
      <div style={{ height: 400, width: '100%', marginBottom:20 }}>
        <DataGrid
          rows={dataArray}
          columns={columns}
          getRowId={(row) => row.id}
          autoHeight
          componentsProps={{ toolbar: { csvOptions: { allColumns: true } } }}
          components={{
            Toolbar: CustomToolbar,
          }}
          pageSize={pageSize}
          onPageSizeChange={(value) => setPageSize(value)}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />

        
      </div>

    </div>
  )
}

export default Datagrid
