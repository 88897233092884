import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import data from '../../dummy/dummyData.json'
import IProduct from '../Home/interfaces';
const apiUrl = process.env.REACT_APP_APIURL;

const OnGoing = () => {
    const [dataProduct, setDataProduct] = React.useState<IProduct[]>([]);


    const fetchData = async () => {
        try {
          const response = await axios.get(apiUrl+'/public/getProduct');
          setDataProduct(response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    useEffect(() => {
        fetchData();
        
      }, []);
  return (
    <div className='mx-auto my-5 w-full lg:w-3/4'>
      <h1 className='font-bold sm:text-2xl md:text-3xl text-white'>All Item</h1>
      <div className='grid grid-cols-2 lg:grid-cols-3 lg:gap-3'>
        {dataProduct.map((x) => (
          <div className="bg-white rounded-lg border border-gray-300 shadow-md p-4 m-4" style={{maxWidth:400, maxHeight:600}} key={x.id}>
              <div className='w-full'>
                  <img src={x?.mainImagePath === '' ? require('../../img/no-image.jpg') : x?.mainImagePath} alt="Item" width={400} height={250} className="rounded-xl"/>
              </div>
              <h1 className='p-1 mt-2 border-2 border-gray-300 rounded-lg text-gray-400 text-center' style={{maxWidth:150}}>{x.category}</h1>
              <h1 className='mt-2 font-bold'>{x.title}</h1>
              <div className='flex flex-col mt-2'>
                  <h1>Start From</h1>
                  <h1 className='font-bold text-xl'>Rp. {parseInt(x.latestPrice).toLocaleString()}</h1>
              </div>
              <div className='flex flex-row gap-3'></div>
              <div className='md:float-right'>
                  <Link to={`/detail/${x.id}`} >
                      <button className='w-full bg-bluePrimary shadow-md p-2 rounded-md text-white font-bold'>
                          BID NOW
                      </button>
                  </Link>
              </div>
          </div>
        ))}
    </div>
      
    </div>
  )
}

export default OnGoing
