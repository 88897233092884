import React from 'react'
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_APIURL;

const DoLogin = () => {
  const { isSignedIn, user, isLoaded } = useUser();
  
  const postData = async (id: string, fullname: string, email: string) => {
    try {
      const response = await axios.post(apiUrl+'/auth/doLogin', {
        id: id,
        fullname: fullname,
        email: email
      });

      if (response.data.status) window.location.href = '/';
      else console.log(response.data.message)
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };
  
  React.useEffect(() => {
    if (isSignedIn) {
      console.log(user.id)
      console.log(user.fullName)
      console.log(user.emailAddresses[0].emailAddress)
      postData(user.id, user.fullName ?? '', user.emailAddresses[0].emailAddress)
    }
  }, [])

  return (
    <div>Authenticating...</div>
  )
}

export default DoLogin
