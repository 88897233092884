import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import data from '../../dummy/dummyData.json'
import IProduct from './interfaces';
const apiUrl = process.env.REACT_APP_APIURL;

const Home = () => {
    const [dataProduct, setDataProduct] = React.useState<IProduct[]>([]);
    const [isDialogOpen, setIsDialogOpen] = React.useState(true);

    const fetchData = async () => {
        try {
          const response = await axios.get(apiUrl+'/public/getProduct');
          setDataProduct(response.data.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      const confirmOkDialog = () => {
        localStorage.setItem('isDialogConfirmed', 'true');
        setIsDialogOpen(false)
      };

    useEffect(() => {
      const isDialogConfirmed = localStorage.getItem('isDialogConfirmed');
        if (isDialogConfirmed) {
            setIsDialogOpen(false);
        }
        fetchData();

        
      }, []);


  return (
    <div className='mx-auto my-5 w-full lg:w-3/4'>
        <div className='mb-4'>
        <div className='flex justify-between'>
        <h1 className='font-bold sm:text-2xl md:text-3xl text-white'>Newest Item</h1>
        <Link to="/all-item" className='text-white'>
            View All
        </Link>
        </div>
        <div className='grid grid-cols-2 lg:grid-cols-3 lg:gap-3'>
            {dataProduct.slice(0, 6).map((x) => (
                <div className="bg-white rounded-lg border border-gray-300 shadow-md p-4 m-4" style={{maxWidth:400, maxHeight:600}} key={x.id}>
                    <div className='w-full'>
                        <img src={x?.mainImagePath === '' ? require('../../img/no-image.jpg') : x?.mainImagePath} alt="Item" width={400} height={250} className="rounded-xl"/>
                    </div>
                    <h1 className='p-1 mt-2 border-2 border-gray-300 rounded-lg text-gray-400 text-center' style={{maxWidth:150}}>{x.category}</h1>
                    <h1 className='mt-2 font-bold'>{x.title}</h1>
                    <div className='flex flex-col mt-2'>
                        <h1>Start From</h1>
                        <h1 className='font-bold text-xl'>Rp. {parseInt(x.latestPrice).toLocaleString()}</h1>
                    </div>
                    <div className='flex flex-row gap-3'></div>
                    <div className='md:float-right'>
                        <Link to={`/detail/${x.id}`} >
                            <button className='w-full bg-bluePrimary shadow-md p-2 rounded-md text-white font-bold'>
                                BID NOW
                            </button>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    </div>

    {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded border-2 shadow-lg w-3/4">
            <h1><strong>Terms &amp; Condition</strong></h1>
            <p className='p-4' style={{overflow:'scroll', maxHeight:'600px'}}>
              <h5 className='pt-8'><strong>Dengan mengklik tombol OK maka Anda telah memberikan persetujuan untuk Syarat dan Ketentuan Lelang dibawah ini.</strong></h5>
              <h5 className='pt-8'><strong>Syarat dan Ketentuan Lelang</strong></h5>
                <ol style={{listStyle:'number'}}>
                    <li><span className='font-bold'>Peserta Lelang</span>
                        <ol style={{listStyle:'lower-alpha'}} className='pl-5'>
                          <li>Semua Karyawan berhak untuk menjadi peserta lelang</li>
                          <li>Peserta lelang tunduk dan taat pada Syarat dan Ketentuan lelang</li>
                          <li>Peserta lelang dianggap melakukan penawaran lelang secara sadar tanpa paksaan dari pihak manapun dan mengetahui barang yang dibeli</li>
                          <li>Peserta lelang bertanggung jawab penuh atas penawaran lelang yang dilakukan</li>
                        </ol>
                    </li>
                    <li><span className='font-bold'>Lama waktu lelang adalah 1 jam dengan acuan waktu server</span></li>
                    <li><span className='font-bold'>Peserta lelang melakukan penawaran lelang dengan kelipatan Rp.100.000,-</span></li>
                    <li><span className='font-bold'>Penentuan pemenang lelang</span>
                        <ol style={{listStyle:'lower-alpha'}} className='pl-5'>
                            <li>Harga Tertinggi dan waktu tercepat</li>
                            <li>Jumlah maksimum barang yang dapat dibeli adalah 2 pcs/pemenang lelang dengan kategori yang berbeda. <br />Jika barang yang dimenangkan lebih dari 2 pcs maka Pemenang hanya akan mendapatkan 2 pcs dengan kategori yang beda</li>
                            <li>Pemenang lelang yang tidak sesuai dengan ketentuan yang berlaku maka akan dianulir dan barang akan berpindah ke peserta lelang dibawahnya dan seterusnya</li>
                            <li>Pemenang lelang tidak boleh mengundurkan diri</li>
                        </ol>
                    </li>
                    <li><span className='font-bold'>Pembayaran</span>
                        <ol style={{listStyle:'lower-alpha'}} className='pl-5'>
                            <li>Pelunasan kewajiban pembayaran lelang oleh Pemenang Lelang dilakukan secara transfer ke 0842563494 a/n PT GMV setelah pengumuman pemenang lelang.</li> 
                            <li>Maks pembayaran adalah 3 (tiga) hari setelah pengumuman pemenang lelang</li>
                            <li>Jika hingga batas akhir pembayaran Pemenang belum juga melakukan transfer maka pembayaran akan dilakukan dengan pemotongan gaji</li>
                            <li>Pemenang wajib menunjukkan bukti pembayaran yang telah dikonfirmasi oleh FA ketika pembelian barang</li>
                        </ol>
                    </li>
                    <li><span className='font-bold'>Pemenang wajib membawa barang yang dimenangkan setelah diserahkan oleh GA.</span> (GA tidak menerima penitipan barang)</li>
                    <li><span className='font-bold'>Jika terbukti adanya suatu kecurangan</span> yang melibatkan Pemenang dan tim yang terlibat dalam proses lelang maka kemenangan akan dibatalkan dan karyawan yang terlibat akan diproses sesuai dengan Peraturan Perusahaan</li>
                </ol>
            </p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={confirmOkDialog}
              >
                OK
              </button>

            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Home
