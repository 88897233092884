import React, { useEffect } from 'react'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';
import {HiOutlineViewList} from 'react-icons/hi'
import {IoClose} from 'react-icons/io5'
import {RiAccountCircleLine} from 'react-icons/ri'
import data from '../../dummy/dummyData.json'
import { ClerkProvider, SignInButton, SignedIn, SignedOut, UserButton, useUser } from '@clerk/clerk-react';
import { ICategory } from './interfaces';
import axios from 'axios';


const HeaderBE = () => {
    const { isSignedIn, user, isLoaded } = useUser();
    const location = useLocation();
    const navigate = useNavigate()
    const isSearchPage = useMatch('/search')


    const categories = ["Category 1", "Category 2", "Category 3"];

    const listCategories = [{id: '1', label: 'Car', url: '/car'}, {id: '2', label: 'Motorcycle', url: '/motorCycle'}];
    const uniqueCategories = Array.from(new Set(data.map(item => item.category)));

    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenProfile, setIsOpenProfile] = React.useState(false);
    const [keyword, setKeyword] = React.useState<string>('')
    const [dataCategories, setDataCategories] = React.useState<ICategory[]>([]);
    const [isDropdownOpen, setDropdownOpen] = React.useState(false);
    const [isDropdownOpenStock, setDropdownOpenStock] = React.useState(false);
    const [isDropdownOpenReport, setDropdownOpenReport] = React.useState(false);
  
  
    const toggleDropdown = () => {
      setDropdownOpen(!isDropdownOpen);
    };
  
    const toggleDropdownReport = () => {
      setDropdownOpenReport(!isDropdownOpenReport);
    };
    const toggleDropdownStock = () => {
      setDropdownOpenStock(!isDropdownOpenStock);
    };
  

    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://bidhub.temandigital.id/api/public/getCategory');
        setDataCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setIsOpenProfile(false)
  };

  const toggleProfile = () => {
    setIsOpenProfile(!isOpenProfile);
    setIsOpen(false)
  };

  const onCloseProfile = () => {
    setIsOpenProfile(false)
  }

  const handleSearch = () => {
    if (keyword) {
      const keywordValue = encodeURIComponent(keyword)

      navigate(`/search?keyword=${keywordValue}`, { state: { from: location }, replace: true })

      if (isSearchPage) navigate(0)
    } else {
      navigate(`/search`, { state: { from: location }, replace: true })
      if (isSearchPage) navigate(0)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission or any default behavior
      handleSearch();
    }
  }

  useEffect(() => {
    //fetchCategories();
    
  }, []);
  return (

      <header className=" text-black py-3 ">
        <div className='w-full mx-auto flex items-center'>
          <nav className='mx-4 w-full'>
            <ul className="flex space-x-4 gap-5 justify-between">
              <div className='flex flex-row gap-5 sm:hidden '>
                <li className='mr-6  '>
                  <button onClick={toggleDrawer} className="text-black font-bold text-2xl my-auto border-2 shadow-lg p-1 rounded-lg border-black hover:bg-orange-300">
                    <HiOutlineViewList size={30}/>
                  </button>
                </li>
                <div className='hidden sm:block'>
                  <li className={location.pathname === '/' ? 'text-orange-500 my-auto' : 'my-auto'}>
                    <Link to="/" className='font-bold xs:text-xl md:text-2xl'>Home</Link>
                  </li>
                </div>
                {/* <div className='hidden md:block'>
                  <li className={location.pathname === '/onGoing' ? 'text-orange-600 my-auto' : 'my-auto'}>
                    <Link to="/onGoing" className='font-bold xs:text-xl md:text-2xl my-auto'>On Going</Link>
                  </li>
                </div> */}
              </div>

              <div className='hidden  sm:block'>
            
              </div>

              <li className='justify-end'>
                <SignedIn>
                  <UserButton afterSignOutUrl="/" /> {isSignedIn ? user.fullName : ''}
                </SignedIn>
                <SignedOut>
                  <SignInButton afterSignUpUrl="/doRegister" />
                </SignedOut>
              </li>
            </ul>
          </nav>
        </div>
        <div className={`fixed top-0 left-0 h-screen w-60 bg-gray-800 text-white z-10 ${isOpen ? 'translate-x-0' : '-translate-x-60'} transition-transform duration-300 ease-in-out`}>
          {/* Drawer content here */}
          <button onClick={toggleDrawer} className="absolute top-4 text-white right-4 p-2">
            <IoClose size={30}/>
          </button>
          <nav className="mt-10 ">
        <SidebarItem to="/admin/dashboard" label="Dashboard" />
        <div className="relative">    
          <div
            onClick={toggleDropdown}
            className="w-full text-left p-2 pr-8 focus:outline-none hover:bg-gray-700 cursor-pointer"
          >
            <div className='flex flex-row justify-between font-bold'>
                <h1>Manage Users</h1>
                <h1>&gt;</h1>
            </div>
          </div>
          {isDropdownOpen && (
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/user-management" label="User Management" />
              {/* <SidebarItem to="/admin/users/create" label="Create User" /> */}
            </div>
          )}
        </div>
        <div className="relative"> 
          <div
            onClick={toggleDropdownStock}
            className="w-full text-left p-2 pr-8 focus:outline-none hover:bg-gray-700 cursor-pointer"
          >
            <div className='flex flex-row justify-between font-bold'>
                <h1>Manage Item</h1>
                <h1>&gt;</h1>
            </div>
          </div>
          {isDropdownOpenStock && (
            <div>
              <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/item-management" label="Product" />
            </div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/category-management" label="Category" />
            </div>
            </div>
          )}
        </div>
        <div className="relative"> 
          <div
            onClick={toggleDropdownReport}
            className="w-full text-left p-2 pr-8 focus:outline-none hover:bg-gray-700 cursor-pointer"
          >
            <div className='flex flex-row justify-between font-bold'>
                <h1>Report</h1>
                <h1>&gt;</h1>
            </div>
          </div>
          {isDropdownOpenReport && (
            <div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/transaction-report" label="Transaction Report" />
            </div>
            <div className=" top-full left-0 w-full bg-gray-800">
              <SidebarItem to="/admin/highest-bidder" label="Top 5 Highest Bidder" />
            </div>
            </div>
          )}
        </div>
      </nav>
        </div>
      </header>
  )
}

interface SidebarItemProps {
  to: string;
  label: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({ to, label }) => {
  return (
   
      <Link to={to} className="block p-2 pl-3 hover:bg-gray-700">
        {label}
      </Link>

  );
};

export default HeaderBE
