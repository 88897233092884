import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import data from '../../dummy/dummyData.json'

const AdminPage = () => {
    const filteredCarData = data.filter((x) => x.categoryId === 'CAR') 
    const filteredMotorCycleData = data.filter((x) => x.categoryId === 'MOTOR') 


  return (
    <div className='relative'>
        
    </div>
  )
}

export default AdminPage
