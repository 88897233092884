import { Button, IconButton } from '@mui/material';
import React from 'react'
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


import "react-datepicker/dist/react-datepicker.css";
import { DatagridProps } from './interfaces';
import moment from 'moment';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../constants/Parameter';
import { IProductBE } from '../../interfaces';
import { RiPencilFill } from 'react-icons/ri';
import { IoTrash } from 'react-icons/io5';

  interface Row {
    id: number;
    title: string;
    category: string;
    image: string[];
    startingPrice: number;
    biddingStart: string;
    biddingEnd: string;
    description: string
    highestBid: number;
    secondHighestBid: number;
    thirdHighestBid: number;
    fourthHighestBid: number;
    fifthHighestBid: number;
  }

const Datagrid = ({
  dataArray,
  // loading = false, 
  onOpenDetail,
  onOpenDelete, 
  onCloseDetail
}:DatagridProps) => {

  const [pageSize, setPageSize] = React.useState<number>(PAGE_SIZE)
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([])



  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, minWidth: 120 },
    { field: 'category', headerName: 'Category', flex: 1, minWidth: 120 },
    { field: 'description', headerName: 'Description', flex: 1, minWidth: 120 },
    { field: 'startingPrice', headerName: 'Starting Price', flex: 1, minWidth: 120, 
      renderCell: (params) => {
      return `Rp.${parseInt(params.row.startingPrice).toLocaleString()}`
    },},
    { field: 'postingDate', headerName: 'Posting Date', flex: 1, minWidth: 155,
    renderCell: (params) => {
      return moment(params.row.postingDate).format('DD-MMM-YYYY HH:mm:ss')
    },
   },
    { field: 'bidStartDate', headerName: 'Bid Start Date', flex: 1, minWidth: 155,
    renderCell: (params) => {
      return moment(params.row.bidStartDate).format('DD-MMM-YYYY HH:mm:ss')
    },
   },
    { field: 'bidEndDate', headerName: 'Bid End Date', flex: 1, minWidth: 155,
    renderCell: (params) => {
      return moment(params.row.bidEndDate).format('DD-MMM-YYYY HH:mm:ss')
    },
   },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => onOpenDetail(params.row.id)}
          >
            <RiPencilFill  />
          </IconButton>
          <IconButton
            onClick={() => onOpenDelete(params.row)}
          >
            <IoTrash color='red'/>
          </IconButton>
        </>
      ),
    },
  ];


  
  return (
<div className="w-full p-4">

      {/* <div className="mb-4">
        <Button variant="contained" color="primary" onClick={()=>onOpenDetail('')}>
          Add
        </Button>
      </div> */}
      {/* <div style={{ width: '100%', height: 700, overflowY: 'auto'  }}>
        <DataGrid
          rows={dataArray}
          columns={columns}
          getRowId={(row) => row.ID}
          autoHeight
          style={{height:700}}
          scrollbarSize={10}
        />
      </div> */}
       <div className="datagrid">
      <div className="datagrid__container">
        <div className="datagrid__content">
          <DataGrid
            columns={columns}
            rows={dataArray}
            pageSize={pageSize}
            onPageSizeChange={(value) => setPageSize(value)}
            rowsPerPageOptions={ROWS_PER_PAGE}
            // components={{
            //   Toolbar: CampusDatagridToolbar,
            // }}
            // componentsProps={{
            //   toolbar: {
            //     disabledDelete: selectionModel.length === 0,
            //     loading: loading,
            //     onAdd: onAdd,
            //     onDelete: () => onDeleteBulk?.(selectionModel),
            //   },
            // }}
            getRowId={(row: IProductBE) => row.id}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 1 ? 'datagrid__row--even' : ''
            }
            //loading={loading}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
            autoHeight
            // checkboxSelection
            disableSelectionOnClick
            disableColumnSelector
          />
        </div>
      </div>
    </div>


    </div>
  )
}

export default Datagrid
