import React, { ReactNode } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './components/Home/Home';
import DoRegister from './components/Auth/doRegister';
import DoLogin from './components/Auth/doLogin';


import OnGoing from './components/OnGoing/OnGoing';
import OnGoingItem from './components/Detail/Detail';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Footer from './components/Footer/Footer';
import History from './views/History/History';
import Profile from './views/Profile/Profile';
import Login from './views/Login/Login';
import Search from './views/Search/Search';
import CategoryPage from './views/CategoryPage/CategoryPage';
import AdminPage from './views/AdminPage/AdminPage';
import UserManagement from './views/UserManagement/UserManagement';
import AuctionManagement from './views/AuctionManagement/AuctionManagement';
import SidebarBE from './components/Sidebar/SidebarBE';
import ReportAuction from './views/ReportAuction/ReportAuction';
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  SignIn,
  SignUp,
  UserButton,
  useUser,
  RedirectToSignIn,
  RedirectToSignUp,
  UserProfile,
} from "@clerk/clerk-react";
import HeaderBE from './components/Header/HeaderBE';
import CategoryManagement from './views/CategoryManagement/CategoryManagement';
import HighestBidder from './views/HighestBidder/HighestBidder';
import MainLayout from './components/MainLayout/MainLayout';
import DoLogout from './components/Auth/doLogout';
if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  console.log(process.env)
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnameParts = location.pathname.split('/');



  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
    >
      <div className={pathnameParts[1] === 'admin' ? '' : 'App'}>
        {(pathnameParts[1] !== 'admin' && pathnameParts[1] !== 'login' && pathnameParts[1] !== 'register') && (<Header/>)}
        {(pathnameParts[1] === 'admin') && (<HeaderBE/>)}
        <div className='flex xs:flex-col sm:flex-row'>
          <div className='' style={pathnameParts[1] === 'admin' ? {width:320}: {width:0}}>
            {pathnameParts[1] === 'admin' && (<SidebarBE/>)}
          </div>
          <div className='w-full mx-2'>
          <MainLayout>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/all-item" element={<OnGoing />} />             
              <Route
                path="/login"
                element={<RedirectToSignIn afterSignInUrl='/doRegister' afterSignUpUrl='/doRegister'/>}
              />

              <Route
                path="/register"
                element={<RedirectToSignUp afterSignInUrl='/doRegister' afterSignUpUrl='/doRegister' />}
              />
              <Route path="/detail/:id" element={<OnGoingItem />} />
              <Route path="/:category" element={<CategoryPage />} />
              <Route path="/search" element={<Search />} />
              <Route path="/doLogout" element={<DoLogout />} />
              <Route
                path="/doRegister"
                element={
                <>
                  <SignedIn><DoRegister /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignInUrl='/doRegister' afterSignUpUrl='/doRegister' /></SignedOut>
                </>
                }
              />
              <Route
                path="/doLogin"
                element={
                <>
                  <SignedIn><DoLogin /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignInUrl='/doRegister' afterSignUpUrl='/doRegister' /></SignedOut>
                </>
                }
              />
              <Route
                path="/admin"
                element={
                <>
                  <SignedIn><AdminPage /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
              <Route
                path="/admin/user-management"
                element={
                <>
                  <SignedIn><UserManagement /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
              <Route
                path="/admin/item-management"
                element={
                <>
                  <SignedIn><AuctionManagement /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
              <Route
                path="/admin/category-management"
                element={
                <>
                  <SignedIn><CategoryManagement /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
              <Route
                path="/admin/transaction-report"
                element={
                <>
                  <SignedIn><ReportAuction /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
              <Route
                path="/admin/highest-bidder"
                element={
                <>
                  <SignedIn><HighestBidder /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
              <Route
                path="/profile"
                element={
                <>
                  <SignedIn><UserProfile /></SignedIn>
                  <SignedOut><RedirectToSignIn afterSignUpUrl='/doRegister' afterSignInUrl='/doRegister'/></SignedOut>
                </>
                }
              />
            </Routes>
            </MainLayout>
          </div>
        </div>
        <div className='bottom-0 mx-auto'>
          {(pathnameParts[1] !== 'admin' && pathnameParts[1] !== 'login' && pathnameParts[1] !== 'register') && (<Footer/>)}
        </div>
      </div>
    </ClerkProvider>
  )
}

function App() {
  return (
    <ClerkProviderWithRoutes />
  )
}

export default App;
