import * as yup from 'yup'
import { IProductBEFormData } from '../interfaces'


export const ProductSchema = yup.object({
  title: yup.string().required().label('Title'),
  category: yup.string().required().label('Category'),
  description: yup.string().required().label('Description'),
  startingPrice: yup.string().required().label('Starting Price'),
  postingDate: yup.string().required().label('Posting Date'),
  bidStartDate: yup.string().required().label('Bid Start Date'),
  bidEndDate: yup.string().required().label('Bid End Date'),
})

export const initialProductFormdata: IProductBEFormData = {
  action: 'Add',
  id: '',
  title: '',
  categoryID: '',
  category: '',
  description: '',
  startingPrice: '',
  postingDate: '',
  bidStartDate: '',
  bidEndDate: '',
  imgUrls: '',
  imgIsMain: '',
  fileData: '',
  fileName: '',
}