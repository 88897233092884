import { Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react'
import DatePicker from "react-datepicker";
import { DataGrid, GridColDef, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


import "react-datepicker/dist/react-datepicker.css";
import { DatagridProps } from './interfaces';

  interface Row {
    id: number;
    title: string;
    category: string;
    image: string[];
    startingPrice: number;
    biddingStart: string;
    biddingEnd: string;
    description: string
    highestBid: number;
    secondHighestBid: number;
    thirdHighestBid: number;
    fourthHighestBid: number;
    fifthHighestBid: number;
  }

  interface Todo {
    userId?: number;
    id: number;
    title: string;
    completed: boolean;
  }
  
const Datagrid = ({dataArray}:DatagridProps) => {

  // const [data, setData] = React.useState<Row[]>(initialData);
  // const [selectedRow, setSelectedRow] = React.useState<Row | null>(null);
  const [pictureFiles, setPictureFiles] = React.useState<FileList | null>(null);
  // const [filterName, setFilterName] = React.useState<string>('');
  const [data, setData] = React.useState<Todo[]>([]);
  const [filteredData, setFilteredData] = React.useState<Todo[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [newTodo, setNewTodo] = React.useState({ title: '', completed: false });
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [editTodo, setEditTodo] = React.useState<Todo | null>(null);


  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 120 },
    { field: 'email', headerName: 'Email', flex: 1, minWidth: 120 },
    {
      field: 'roleID',
      headerName: 'Role',
      flex: 1,
      width: 120,
      renderCell: (params) => (
        <>
        {params.row.roleID == '1' ? 'Admin' : 'User'}
        </>
      ),
    }
  ];


  const fetchData = async () => {
    try {
      const response = await axios.get('https://jsonplaceholder.typicode.com/todos');
      setData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFilter = () => {
    const filtered = data.filter((item) =>
      item.title.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleAdd = () => {
    setData([...data, { ...newTodo, id: data.length + 1 }]);
    setNewTodo({ title: '', completed: false });
  };

  const handleEdit = (todo: Todo) => {
    setEditTodo(todo);
    setEditDialogOpen(true);
  };

  const handleDelete = (todo: Todo) => {
    const updatedData = data.filter((item) => item.id !== todo.id);
    setData(updatedData);
  };
  const handleEditDialogClose = () => {
    setEditTodo(null);
    setEditDialogOpen(false);
  };

  const handleEditSave = () => {
    const updatedData = data.map((item) =>
      item.id === editTodo!.id ? { ...item, ...editTodo } : item
    );
    setData(updatedData);
    handleEditDialogClose();
  };

  useEffect(() => {
    fetchData();
  }, []);
  
  return (
<div className="w-full p-4">
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={dataArray}
          columns={columns}
          getRowId={(row) => row.id}
          autoHeight
          // getRowClassName={(params) =>
          //   params.indexRelativeToCurrentPage % 2 === 1 ? 'datagrid__row--even' : ''
          // }
        />
      </div>

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Edit Todo</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={editTodo?.title || ''}
            onChange={(e) =>
              setEditTodo((prevTodo) => ({
                ...prevTodo!,
                title: e.target.value,
              }))
            }
          />
          {/* Add more fields for editing if needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Datagrid
