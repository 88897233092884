import React from 'react'

const Footer = () => {
  return (
    <footer className=" text-white py-4">
      <div className="container mx-auto text-center">
        <p>&copy; 2023 Bidhub by Teman Digital Indonesia. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer
