import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react'
import { FORMAT_DATE_POST } from '../../constants/Parameter';
import Filter from '../ReportAuction/components/filter/filter';
import { IReportFilter } from '../ReportAuction/components/filter/interfaces';
import Datagrid from './components/datagrid'
import { IHighestBidder } from './interfaces';
const apiUrl = process.env.REACT_APP_APIURL;


const HighestBidder = () => {
  const startOfMonth = moment().startOf('month').format(FORMAT_DATE_POST)
  const endOfMonth = moment().endOf('month').format(FORMAT_DATE_POST)
  const { isSignedIn, user, isLoaded } = useUser();
  const [data, setData] = React.useState<IHighestBidder[]>([]);

  const [activeFilter, setActiveFilter] = React.useState<IReportFilter>({
  StartDate: startOfMonth,
  EndDate: endOfMonth,
  })

  const fetchData = async (userID: string) => {
      try {
        const response = await axios.get(`${apiUrl}/admin/getTop5Highest?sessionID=${userID}`);
        if (response.data.status) setData(response.data.data);
        else window.location.href = '/';
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const onShow = (filter: IReportFilter) => {
      setActiveFilter(filter)
      //fetchData(filter)
    }
  useEffect(() => {
    if (isSignedIn) {
      fetchData(user?.id ?? '');
    } else window.location.href = '/';
      
    }, []);
  return (
    <div className='mx-3'>
      <div className='mb-5'>
      <h1 className='font-bold text-3xl'>TOP 5 HIGHEST BIDDER</h1>
      </div>
      <div style={{ height: 300, width: '100%' }}>
      {/* <Filter onShow={onShow}/> */}
      <Datagrid dataArray={data}/>
      </div>
    </div>
  )
}

export default HighestBidder
