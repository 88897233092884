import { Typography } from '@mui/material'
import { initialCategoryFormdata, CategorySchema } from './constants';
import { IDeleteFormProps, ICategoryBEFormData } from './interfaces'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import DialogForm from '../../../../components/dialog_form';


const Form = ({
    isOpen, 
    onClose, 
    open = false,
    userID,
    onSave,
    selectedID,
    selectedData,
     ...other
    }: IDeleteFormProps) => {

      const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm<ICategoryBEFormData>({
        defaultValues: initialCategoryFormdata,
        //resolver: yupResolver(CategorySchema),
      })
      

    const onEnter = () => {
      }


      const onExit = () => {
        reset(initialCategoryFormdata)
      }

      const onSubmit = (values: ICategoryBEFormData) => {
        const updatedData: ICategoryBEFormData = {
          action: 'Delete',
          id: selectedID ?? '',
          // ID: selectedData ? selectedData.ID : '',
        };
        console.log(updatedData)
         onSave(updatedData);
        // navigation.navigate('Categorys' as never) 
      };



  return (
    <DialogForm
    open={isOpen}
    title={'Delete Item'}
    size="xs"
    onClose={onClose}
    onCancel={onClose}
    onConfirm={handleSubmit(onSubmit)}
    TransitionProps={{
      onEnter: onEnter,
      onExit: onExit,
    }}
    labelConfirmButton="YES"
    labelCancelButton="NO"
    {...other}
  >
    <form>
        <div>
            <Typography>Are you sure want to delete {selectedData?.category}?</Typography>
        </div>
    </form>
  </DialogForm>
  )
}

export default Form


