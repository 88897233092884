import { Button, IconButton } from '@mui/material';
import React from 'react'
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';


import "react-datepicker/dist/react-datepicker.css";
import { DatagridProps } from './interfaces';
import moment from 'moment';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../constants/Parameter';
import { ICategoryBE } from '../../interfaces';
import { RiPencilFill } from 'react-icons/ri';
import { IoEye, IoTrash } from 'react-icons/io5';

  interface Row {
    id: number;
    title: string;
    category: string;
    image: string[];
    startingPrice: number;
    biddingStart: string;
    biddingEnd: string;
    description: string
    highestBid: number;
    secondHighestBid: number;
    thirdHighestBid: number;
    fourthHighestBid: number;
    fifthHighestBid: number;
  }

const Datagrid = ({
  dataArray,
  // loading = false, 
  onOpenDetail,
  onOpenDelete, 
  onCloseDetail
}:DatagridProps) => {

  const [pageSize, setPageSize] = React.useState<number>(PAGE_SIZE)
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([])



  const columns: GridColDef[] = [
    { field: 'category', headerName: 'Name', flex: 1, minWidth: 120 },
    { field: 'bidStartDate', headerName: 'Bid Start Date', flex: 1, minWidth: 120,
    renderCell: (params) => {
      return moment(params.row.bidStartDate).format('DD-MMM-YYYY HH:mm:ss')
    },
   },
    { field: 'bidEndDate', headerName: 'Bid End Date', flex: 1, minWidth: 120,
    renderCell: (params) => {
      return moment(params.row.bidEndDate).format('DD-MMM-YYYY HH:mm:ss')
    },
   },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <IconButton
          style={{ marginLeft: 8 }}
            onClick={() => onOpenDetail(params.row.id)}
          >
            <RiPencilFill  />
          </IconButton>
        </>
      ),
    },
  ];


  
  return (
<div className="w-full p-4">
       <div className="datagrid">
      <div className="datagrid__container">
        <div className="datagrid__content">
          <DataGrid
            columns={columns}
            rows={dataArray}
            pageSize={pageSize}
            onPageSizeChange={(value) => setPageSize(value)}
            rowsPerPageOptions={ROWS_PER_PAGE}
            // components={{
            //   Toolbar: CampusDatagridToolbar,
            // }}
            // componentsProps={{
            //   toolbar: {
            //     disabledDelete: selectionModel.length === 0,
            //     loading: loading,
            //     onAdd: onAdd,
            //     onDelete: () => onDeleteBulk?.(selectionModel),
            //   },
            // }}
            getRowId={(row: ICategoryBE) => row.id}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 1 ? 'datagrid__row--even' : ''
            }
            //loading={loading}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
            autoHeight
            // checkboxSelection
            disableSelectionOnClick
            disableColumnSelector
          />
        </div>
      </div>
    </div>


    </div>
  )
}

export default Datagrid
