import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import './Detail.css'
import data from '../../dummy/dummyData.json'
import {GrPrevious, GrNext} from 'react-icons/gr'
import moment, { Moment } from 'moment'
import IProductDetail, { IBiddingProcess, IProductImage } from './interfaces'
import axios from 'axios'
import { useUser } from '@clerk/clerk-react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
const apiUrl = process.env.REACT_APP_APIURL;

const Detail = () => {

    const {id} = useParams()
    const detailData = data.find((x) => x.id.toString() === id )
    const { isSignedIn, user, isLoaded } = useUser();

   
    const [dataProduct, setDataProduct] = React.useState<IProductDetail | null>(null);
    const [dataBidProcess, setDataBidProcess] = React.useState<IBiddingProcess[]>([]);
    const [dataImages, setDataImages] = React.useState<IProductImage[]>([]);
    const [startDateText, setStartDateText] = React.useState<string>('');
    const [endDateText, setEndDateText] = React.useState<string>('');
    const startDate = moment(startDateText, "YYYY-MM-DD HH:mm:ss");
    const endDate = moment(endDateText, "YYYY-MM-DD HH:mm:ss");
    const [countdown, setCountdown] = React.useState<string | null>(null);
      const [currentBid, setCurrentBid] = React.useState<number>(0)
      const [myBid, setMyBid] = React.useState<number>(0)
      const [isDialogOpen, setIsDialogOpen] = React.useState(false);
      const [isDialogSuccessOpen, setIsDialogSuccessOpen] = React.useState(false);
      const initialTime = 30; // Set your initial countdown time in seconds (3661 seconds = 1 hour, 1 minute, and 1 second)
      const [time, setTime] = React.useState<number>(initialTime);
      const [isSubmitted, setIsSubmitted] = React.useState(false);
      const [isDone, setIsDone] = React.useState(false);
      const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isModalOpen, setModalOpen] = React.useState(false);
   const [responseMessage, setResponseMessage] = React.useState<string>('');




  const forceUpdate = React.useReducer((x) => x + 1, 0)[1]


  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/public/getProduct?id=${id}`);
      setDataProduct(response.data.data);  
      const data = response.data.data
      if (data){
        setStartDateText(data.bidStartDate)
        setEndDateText(data.bidEndDate)
        
        forceUpdate()  
      }
            
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchImages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/public/getProductImage?id=${id}`);
      const data: IProductImage[] = response.data.data
      if (data){
      setDataImages(data)
      }            
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchBiddingProcess = async (isProcessMyBid: boolean) => {
    try {
      const response = await axios.get(`${apiUrl}/public/getBidProcess?productID=${id}`);
      setDataBidProcess(response.data.data);
      const data = response.data.data
      if (data) {
        setCurrentBid(data[0].bidPrice)
        // setMyBid(data[0].bidPrice)
        // console.log(data)

        if (isSignedIn && isProcessMyBid) {
          const mybidhistory = data.filter((x:IBiddingProcess) => x.userID == user?.id)
          setMyBid(mybidhistory[0].bidPrice)
        }
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const postBid= async (sessionID: string, productID: string, bidPrice: number) => {
    try {
      const response = await axios.post(apiUrl+'/public/doBid', {
        sessionID: sessionID,
        productID: productID,
        bidPrice: bidPrice
      });
      setResponseMessage(response.data.message)
      console.log(response.data.message)
      fetchBiddingProcess(true);
    } catch (error:any) {
      console.error('Error making POST request:', error);
      setResponseMessage(error.message)
      fetchBiddingProcess(true);
    }
  };

  const handlePrev = () => {
    if (dataImages){
    setCurrentIndex((prevIndex) => (prevIndex - 1 + dataImages.length) % dataImages.length);
    }
  };

  const handleNext = () => {
    if (dataImages){
    setCurrentIndex((prevIndex) => (prevIndex + 1) % dataImages.length);
    }
  };

  const openModal = (index: number) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };



  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialogSuccess = () => {
    setIsDialogSuccessOpen(true);
    closeDialog()
  };

  const closeDialogSuccess = () => {
    setIsDialogSuccessOpen(false);
  };


  const confirmAction = () => {
    // Handle the confirmation action here
    // For example, delete an item or perform an action
    if (isSignedIn){
    postBid(user?.id ?? '', id ?? '', myBid)
    setIsDialogSuccessOpen(true);
    closeDialog();
  }else {
    alert('Please Login First')
  }
  };

  const confirmSuccessAction = () => {
    // Handle the confirmation action here
    // For example, delete an item or perform an action
    // if (responseMessage == 'Bid success!'){
    //   setCurrentBid(myBid)
    // }
    closeDialogSuccess();
    setIsSubmitted(true)
  };

const addBid = () => {
  let addCost = 0
  if (currentBid == 0) {
    addCost = parseInt(dataProduct?.startingPrice ?? '0')
  } else {
    addCost = parseInt(currentBid.toString())
  }
  if (myBid > addCost) setMyBid(parseInt(myBid.toString()) + 100000)
  else setMyBid(addCost + 100000)
}

const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;

    const pad = (value: number) => (value < 10 ? `0${value}` : value.toString());

    return `${pad(hours)}:${pad(minutes)}:${pad(secondsLeft)}`;
  };


  const calculateCountdown = (startDate: Moment, endDate: Moment): string | null => {
    const duration = moment.duration(endDate.diff(moment()));
  
    if (duration.asMilliseconds() <= 0) {
      return 'Bidding end';
    }
  
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
  
    let retVal = ''
    if (days > 0) retVal = days + 'd, ' + hours + 'h, ' + minutes + 'm, ' + seconds + 's'
    else {
      if (hours > 0) retVal = hours + 'h, ' + minutes + 'm, ' + seconds + 's'
      else {
        if (minutes > 0) retVal = minutes + 'm, ' + seconds + 's'
        else {
          if (seconds > 0) retVal = seconds + 's'
          else setIsDone(true)
        }
      }
    }

    return retVal;
  };

      // useEffect(() => {
      //   const timer = setInterval(() => {
      //     if (time > 0) {
      //       setTime(time - 1);
      //     } else {
      //       clearInterval(timer);
      //       // alert('Time is up!');
      //       setIsDone(true)
      //        // Display an alert when the timer reaches zero
      //     }
      //   }, 1000);
    
      //   return () => clearInterval(timer);
      // }, [time]);

useEffect(() => {
      fetchData();
      fetchImages();
      // console.log(user)
      //etchBiddingProcess()
      //if (isLoaded) fetchBiddingProcess()

      if (startDateText != '' && endDateText != '') {
        const startDate = moment(startDateText, "YYYY-MM-DD HH:mm:ss");
        const endDate = moment(endDateText, "YYYY-MM-DD HH:mm:ss");
    
        const interval = setInterval(() => {
          setCountdown(calculateCountdown(startDate, endDate));
          fetchBiddingProcess(false)
        }, 1500);
    
        return () => clearInterval(interval);
      }
}, [startDateText, endDateText, isLoaded]);
      

  return (
    <div className='mx-auto w-full lg:w-3/4 mt-5 sm:flex sm:flex-row  gap-2'>
    <div className='w-full'>
    <div>
      {/* <div style={{maxHeight:500}} className="w-full mx-auto mt-3 bg-black">
        <img src={require('../../img/car1.png')} width={600} height={1000} className="mx-auto"/> 
      </div> */}
      <div className="relative">
      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 font-bold text-white px-2 py-1 rounded z-10"
        onClick={handlePrev}
      >
        <GrPrevious size={30} />
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 font-bold text-white px-2 py-1 rounded z-10"
        onClick={handleNext}
      >
        <GrNext size={30} />
      </button>
      <div className="w-full h-80 relative overflow-hidden rounded-xl">
        <img
          className="w-full h-full object-cover transform transition-transform duration-300 cursor-pointer"
          src={dataImages[currentIndex]?.imagePath ? dataImages[currentIndex]?.imagePath : require('../../img/no-image.jpg')  }
          alt={`Image ${currentIndex + 1}`}
          onClick={() => openModal(currentIndex)}
          // src={dataProduct?.mainImagePath === '' ? require('../../img/no-image.jpg') : dataProduct?.mainImagePath }
          // alt={dataProduct?.category}
        />
      </div>
      <div className="mt-4 flex justify-center">
        {dataImages?.map((image, index) => (
          <img
            key={index}
            className={`w-12 h-12 rounded-xl object-cover mx-2 cursor-pointer border ${
              index === currentIndex ? 'border-blue-500' : 'border-gray-400'
            }`}
            src={image?.imagePath}
            alt={`Image ${index + 1}`}
            onClick={() => openModal(index)}
          />
        ))}
      </div>

{dataImages[currentIndex]?.imagePath && (
<Dialog open={isModalOpen} onClose={() => setModalOpen(false)} style={{width:'100%'}} className='bg-gray-800 bg-opacity-80'>
        <div className="relative">
            <span
              className="absolute top-2 right-7 text-white text-3xl cursor-pointer"
              onClick={closeModal}
            >
              &times;
            </span>
            <img
              className="max-w-3/4 max-h-3/4 object-contain"
              src={dataImages[currentIndex]?.imagePath}
              alt={`Image ${currentIndex + 1}`}
            />
             <button
              className="absolute top-1/2 left-2 transform -translate-y-1/2 font-bold text-white px-2 py-1 rounded z-10"
              onClick={handlePrev}
            >
              <GrPrevious size={30} color='white' />
            </button>
            <button
              className="absolute top-1/2 right-2 transform -translate-y-1/2 font-bold text-white px-2 py-1 rounded z-10"
              onClick={handleNext}
            ><GrNext size={30} /></button>
          </div>
        {/* <DialogActions>
          <Button onClick={onSubmit} color="primary" autoFocus variant='contained'>
            YES
          </Button>
          <Button onClick={() => setModalOpen(false)} color="error" autoFocus variant='contained'>
            NO
          </Button>
        </DialogActions> */}
      </Dialog>
      )}
    </div>
    <div className='mt-4 mb-6'>
      <h1 className='p-1 font-bold text-gray-400  text-center border-2 border-gray-300 rounded-xl mb-2' style={{maxWidth:150}}>{dataProduct?.category}</h1>
      <h1 className='font-bold text-3xl text-white'>{dataProduct?.title}</h1>
      
      <h1 className='text-xl text-white'>{dataProduct?.description.replace(/<p>|<\/p>/g, ' ')}</h1>
      <div className='flex flex-row gap-3 mt-3'>
        <div className=''>
          <h1 className='text-white'>
            Start Bid:
          </h1>
          <h1 className='text-white'>
          {moment(dataProduct?.bidStartDate).format('DD MMM YY HH:mm')}
          </h1>
        </div>
        <div className=''>
          <h1 className='text-white'>
            End Bid:
          </h1>
          <h1 className='text-white'>
          {moment(dataProduct?.bidEndDate).format('DD MMM YY HH:mm')}

          {moment().isBetween(moment(dataProduct?.bidStartDate, "YYYY-MM-DD HH:mm:ss"), moment(dataProduct?.bidEndDate, "YYYY-MM-DD HH:mm:ss")) && (
            <span>
            {countdown !== null ? (
                <span className="font-bold text-red-500 "> ({countdown})</span>
              ) : (
                <span className='text-white'> (Calculating...)</span>
              )}
              </span>
          )}
          </h1>
        </div>

      </div>
      
    
      </div>
    </div>
    <div className='mt-4 flex flex-row flex-wrap justify-evenly border-2 p-4 rounded-lg shadow-lg'>
    <div className='flex flex-col text-xl text-center text-white' style={{width:250}}>
            <h1>Starting Price</h1>
            <div className='font-bold shadow-md rounded-md p-3'>
            <h1>Rp. {parseInt(dataProduct?.startingPrice ?? '0').toLocaleString()}</h1>
            </div>
        </div>
        {moment().isBetween(moment(dataProduct?.bidStartDate, "YYYY-MM-DD HH:mm:ss"), moment(dataProduct?.bidEndDate, "YYYY-MM-DD HH:mm:ss")) && (
        <div>
        <div className='flex flex-col text-xl text-center' style={{width:250}}>
            <h1 className='text-white'>Highest Bid</h1>
            <div className='text-3xl font-bold'>
            <h1 className='text-green-500'>Rp. {parseInt(currentBid.toString()).toLocaleString()}</h1>
            </div>
        </div>
        <div className='flex flex-col'>
        <div className='mt-4 flex flex-col text-xl text-center' style={{width:250}}>
            {/* <h1 className='text-white'>My Last Bid</h1> */}
            <div className={`${myBid <= currentBid ? 'text-red-500' : 'text-white'} border font-bold shadow-md  rounded-md p-3`}>
            <h1>Rp. {parseInt(myBid.toString()).toLocaleString()}</h1>
            </div>
            {/* {myBid <= currentBid && (
                <h1 className='text-sm text-white'>* Your next bid has to be higher than current bid</h1>
            )} */}
        </div>
        {!isDone && (
        <div className=''>
        <div className='mt-4 mx-auto'>
            <h1 className='text-white'>Add Bid :</h1>
        <div className='flex flex-row text-lg'>
            <div className='border-2 border-green-600 font-bold shadow-md  rounded-md p-2 w-full'>
            <h1 className='text-green-600 text-center'>Rp. 100,000</h1>
            </div>
            <div className='my-auto ml-2'>
                <button onClick={addBid} className='bg-green-600 shadow-md p-2 rounded-md text-white font-bold'> + </button>
            </div>
        </div>
        </div>
        <div className='mt-6'>
              <button 
              onClick={openDialog} 
              disabled={myBid <= currentBid ? true : false}
              className={`${myBid <= currentBid ? 'bg-gray-300' : 'bg-bluePrimary'} text-xl shadow-lg p-3 flex mx-auto rounded-md text-white font-bold`}
              > 
              SUBMIT BID 
              </button>
        </div>
        </div>
        )}
        </div>
        </div>
        )}
    </div>
    </div>
    <div className='border-2 p-2 rounded-lg shadow-lg sm:w-1/2 md:w-1/3 my-3 h-screen' style={{ overflowY: 'scroll',}}>
        <h1 className='font-bold mb-2 text-white'>Bid Record</h1>

        {dataBidProcess.map((x) => (
        <div key={x.id} className='border shadow-lg rounded-lg p-3 w-full mb-2'>
          {user?.fullName == x.name && (
            <h1 className='font-bold text-white'>{x.name}</h1>
            )}
            <h1 className='xs:text-xs md:text-sm font-bold text-green-500'> + Rp.{parseInt(x.bidPrice).toLocaleString()} </h1>
        </div>
        ))}

    </div>

    {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
          <div className="bg-white p-4 rounded border-2 shadow-lg sm:w-3/4 md:w-1/3">
            <p>Are you sure you want to submit Rp.{myBid.toLocaleString()} ?</p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-bluePrimary  text-white font-bold py-2 px-4 rounded mr-2"
                onClick={confirmAction}
              >
                Confirm
              </button>
              <button
                className="bg-red-500  text-white font-bold py-2 px-4 rounded"
                onClick={closeDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

{isDialogSuccessOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded border-2 shadow-lg w-1/3">
            <p>{responseMessage}</p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={confirmSuccessAction}
              >
                OK
              </button>

            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Detail
